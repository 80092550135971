import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import NavBar from "../components/navbar";
import clockicon from "../images/clock.svg";
import Helmet from "react-helmet";

function readingTime(text: string): number {
  return Math.round(text.split(" ").length / 350);
}

const PostPage = ({ data }) => {
  const { body, frontmatter } = data.mdx;
  return (
    <main
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      }}
      className="dark:bg-gray-900 bg-white"
    >
      <NavBar />
      <Helmet>
        <title>{frontmatter.title} – Anysphere</title>
      </Helmet>
      <div className="w-full grid justify-items-center pt-12 px-4 pb-8 ">
        <article className="max-w-3xl">
          <h1 className="text-5xl font-bold pb-8 leading-snug dark:text-white">
            {frontmatter.title}
          </h1>
          <p
            className="text-gray-400 pb-2 text-center"
            style={{ color: "#bbb9b5" }}
          >
            {frontmatter.author} — {frontmatter.date}
          </p>
          <p
            className="text-gray-400 pb-8 text-center"
            style={{ color: "#bbb9b5" }}
          >
            <svg
              className="inline-block w-4 mr-2 align-text-bottom"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 27 27"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
            {readingTime(body)} minutes
          </p>
          <div className="prose prose-stone prose-lg dark:prose-invert prose-quoteless max-w-none">
            <MDXProvider>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          </div>
        </article>
      </div>
    </main>
  );
};

export const query = graphql`
  query POST_BY_SLUG($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
      }
    }
  }
`;

export default PostPage;
